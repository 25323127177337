import React from 'react'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../assets//images/terms-of-service.jpg'

const TermsOfService = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />
            <PageBanner
                pageTitle="Conditions d'Utilisation"
                homePageText="Menu principal"
                homePageUrl="/" 
                activePageText="Conditions d'Utilisation"
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <img src={termsImg} alt="tt" />
                                <p><i>Those Terms of Service Policy were last updated on March 1, 2021.</i></p>

                                <h2>Terms of Service Content Overview</h2>

                                <h3>1. What Data We Get</h3>
                                <blockquote className="blockquote">
                                    <p>We collect certain data from you directly, like information you enter yourself and data from third-party platforms you connect with like LinkedIn. We also collect some data automatically, like information about your device and what parts of our Services you interact with or spend time using, for technical display reasons and a better user experrience.</p>
                                </blockquote>

                                <h3>2. Data You Provide to Us</h3>
                                <p>We may collect different data from or about you depending on how you use the Services. Below are some examples to help you better understand the data we collect.</p>
                                <h3>3. How We Get Data About You</h3>
                                <p>We use tools like cookies, analytics services. Some of these tools offer you the ability to opt out of data collection.</p>
                                <h3>4. What We Use Your Data For</h3>
                                <ol>
                                    <li>Responding to your questions and concerns;</li>
                                    <li>Sending you administrative messages and information, including messages from Peers, Recruiters, Line Managersand, Ambassadors, Experts, notifications from Users and about changes to our Service, and updates to our agreements;</li>
                                    <li>Sending push notifications to your wireless device to provide updates and other relevant messages (which you can manage from the “options” or “settings” page of the app);</li>
                                </ol>
                                <h3>5. Your Choices About the Use of Your Data</h3>
                                <p>You can choose not to provide certain data to us, but you may not be able to use certain features of the Services.</p>
                                <ul>
                                    <li>{/* To stop receiving promotional communications from us, you can opt out by using the unsubscribe mechanism in the promotional communication you receive or by changing the email preferences in your account.*/} Note that regardless of your email preference settings, we will send you transactional and relationship messages regarding the Services, including administrative confirmations, order confirmations, important updates about the Services, and notices about our policies.</li>
                                    <li>The browser or device you use may allow you to control cookies and other types of local data storage. Your wireless device may also allow you to control whether location or other data is collected and shared.</li>
                                    <li>To update data you provide directly, log into your account and update your account at any time.</li>
                                </ul>

                                <h1>Terms Of Service</h1>
                                    <h2>General Terms and Conditions</h2>

                                <blockquote className="blockquote">
                                <p>The following General Terms and Conditions (GTC) shall apply to all products and services offered by Cross Stream Solutions SARL (hereinafter referred to as "CSS"). By using our services, you accept the following Terms and Conditions unaltered and in full.</p>
                                </blockquote>


                                <ol>
                                        <li>
                                            <h3>SCOPE OF APPLICATION</h3>
                                            <ol>
                                                <li>
                                                    <h4>Subject Matter of the Agreement</h4>
                                                </li>
                                            </ol>
                                        </li>
                                </ol>
                                    <p>The subject matter of these GTC is the legal relationship between CSS on the one hand and regular users, jobseekers, companies seeking to fill vacancies and recruitment agencies using its mobile application and partner platforms on the other hand (hereinafter the “User”) with regard to all the products and services offered by CSS. These GTC shall apply with regard to each and every use of the products and services offered by CSS (in particular the use of the mobile app called Crowlr).</p>
                                    <ol start="1">
                                        <ol start="2">
                                            <li>
                                                <h4>Conclusion of Contract</h4>
                                            </li>
                                        </ol>
                                    </ol>
                                    <p>Consent to these GTC shall be given by signing a written cooperation agreement or a written offer, by explicit or implied acceptance of an order confirmation, by payment of an invoice or by use of the products and services offered by CSS.</p>
                                    <ol start="1">
                                        <ol start="3">
                                            <li>
                                                <h4>Integral Parts of the Contract</h4>
                                            </li>
                                        </ol>
                                    </ol>
                                    <p>Integral parts of the contract are (where they exist in particular in a given case) (i) the written cooperation agreement concluded between CSS and the User, (ii) the user-specific written offer, (iii) the written order confirmation, (iv) these GTC and (v) the product of CSS applicable at the time a product or service is used. In the case of ambiguities or contradictions between the individual contract documents, the above stated order of priority shall apply. With regard to data protection, the Privacy Policy in force at any given time on the respective platform of CSS shall apply.</p>
                                    <ol start="2">
                                        <li>
                                            <h3>SERVICES OF</h3>
                                            <ol>
                                                <li>
                                                    <h4>Service Offer</h4>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p>The User selects the services to be performed by CSS among the services on offer at the time of his use. The current service offer is described on the respective websites of CSS.</p>
                                    <ol start="2">
                                        <ol start="2">
                                            <li>
                                                <h4>Scenario Account and IM Quota</h4>
                                            </li>
                                        </ol>
                                    </ol>
                                    <p>For companies seeking to fill vacancies and recruitment agencies, CSS in particular offers the services “Scenario Account” and “IM Quota”. For the scope of services, the product descriptions published on the websites of CSS at the time of use of the service shall apply.</p>
                                    <ol start="2">
                                        <ol start="2">
                                            <ol>
                                                <li><h4>Scenario Account</h4></li>
                                            </ol>
                                        </ol>
                                    </ol>
                                    <p>In the case of a Scenario Account, the User has, during the fixed contract term, an agreed number of Scenario available to it. The User is entitled, when removing a Scenario, to directly add a new Scenario. A Scenario limit of 8 means, e.g., that the User cannot create more than 8 Scenario.</p>
                                    <ol start="2">
                                        <ol start="2">
                                            <ol start="2">
                                                <li><h4>IM Quota (Instant Messages)</h4></li>
                                            </ol>
                                        </ol>
                                    </ol>
                                    <p>In the case of an IM Quota, the User purchases a quantity of IM to be used with the Crowlr application when contacting Users. IM are not saved for future purposes on any of the platforms of CSS. The duration of the quota as stipulated in the respective product description or in another integral part of the contract shall apply. Unused IM shall expire and shall not be reimbursed.</p>
                                    <ol start="3">
                                        <li><h3>USE OF THE SERVICE OFFER</h3>
                                            <ol>
                                                <li>
                                                    <h4>Publication of Profiles</h4>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p>By inputting their data, users declare their consent to have their respective profiles made available on the platforms of CSS as well as the partner platforms for companies seeking to fill vacancies and recruitment agencies.
                                        The non-publication or the defective publication of a profile does not give rise to any claims against CSS.</p>
                                    <ol start="3">
                                        <ol start="2">
                                            <li><h4>Allowable Uses</h4></li>
                                        </ol>
                                    </ol>
                                    <p>The User bears the sole responsibility for the contents of its profile. The user represents and warrants that all information contained therein is correct and up-to-date. The mobile application Crowlr from CSS can only be used to publish profiles that comply with all statutory requirements.
                                        Profiles shall not violate the intellectual property, privacy or other rights of third parties (cf. Privacy Policy). The User undertakes not to publish any illegal content, in particular content of a racist, pornographic or personally injurious nature, via CSS.
                                        Inadmissible is in particular the following content:
                                        <ul>
                                            <li>Immoral or offensive profiles</li>
                                            <li>Profiles with erroneous or lacking descriptions</li>
                                            <li>Profils for multi-level marketing, pyramid schemes, home-based work, gift circles etc.</li>
                                            <li>Mystery shopping profils</li>
                                            <li>Offers for continuing education</li>
                                        </ul>
                                        Any published profile must correspond to an actual or past position.
                                        CSS reserves the right to remove from its services any profiles that do not comply in full with these requirements, without prior notice or justification. In addition, CSS reserves the right to block the User or its access to additional services by CSS.
                                    </p>
                                    <ol start="3">
                                        <ol start="3">
                                            <li><h4>Compliance</h4></li>
                                        </ol>
                                    </ol>
                                    <p>Users warrant that they will only use the platforms of CSS and its partner platforms in full compliance with all applicable statutory requirements. Companies, recruitment agencies and employment services firms warrant in particular, that they are in possession of a valid recruitment or hiring of services permit and undertake to provide CSS with a copy thereof on first request.</p>
                                    <ol start="4">
                                        <li><h3>CONTRACT TERM AND RETENTION OF DATA</h3></li>
                                    </ol>
                                    <p>There is no early termination of an agreement concluded for a fixed term or a minimum term. In particular, if profiles or data are deleted early, payment is still owed in full and the User has no right to demand reimbursement of payments already made. The same applies in the event where CSS must delete profiles or block users on the basis of the present GTC or other integral parts of the contract.</p>
                                    <p>CSS is not obligated, following cessation of the Agreement, to retain any data or documents submitted to it, or to return these to the User.</p>
                                    <ol start="5">
                                        <li><h3>PAYMENT TERMS</h3></li>
                                    </ol>
                                    <p>The payment obligations of the User shall commence upon conclusion of contract, without deductions, provided other payment terms have not been explicitly agreed. Where the User is in default in regard to payment of invoices, CSS shall in addition be entitled to suspend its services without prior notice and to block the User or his access to further services of CSS.</p>
                                    <ol start="6">
                                        <li><h3>OFFERS OF THIRD PARTIES</h3></li>
                                    </ol>
                                    <p>CSS also publishes profiles of third parties and/or links to websites of third parties on its platforms. CSS is not responsible for these third-party as well as for the contents of such third-party websites and excludes any and all liability in connection with third-party.</p>
                                    <ol start="7">
                                        <li><h3>LIABILITY</h3>
                                            <ol>
                                                <li>
                                                    <h4>Liability of CSS</h4>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p>CSS performs its services professionally and diligently. CSS is not, however, in a position to guarantee that its services are available without interruption or free of errors. CSS shall in particular not be liable for any interruptions of service and/or technical difficulties and the effects thereof. The profiles accessible on the platforms of CSS originate from the respective Users and CSS assumes no responsibility for the accuracy or completeness of this information.</p>
                                    <p>CSS endeavors to prevent any unauthorized use by third parties of the data published on its platforms. All liability of CSS in the case of unauthorized use by third parties is, however, explicitly excluded. CSS assumes no responsibility for the success of a given profile. In any event, all liability on the part of CSS is limited to illegal intent or gross negligence. Any liability for indirect damages or consequential damages is excluded.</p>

                                    <ol start="7">
                                        <ol start="2">
                                            <li><h4>Liability of Users</h4></li>
                                        </ol>
                                    </ol>
                                    <p>The User is responsible for the legality, accuracy and completeness of the content made available or entered for publication on the platforms of CSS and its partner platforms. The User is responsible for the careful and confidential safekeeping of his user information (in particular user name and passwords) and for all activities carried out through his or her account. The User is liable vis-à-vis CSS irrespective of any fault, for all damages and costs incurred as a result of breaches of these GTC or other integral parts of the contract and shall indemnify CSS for any and all claims asserted by third parties against CSS as a result of the User’s use of the platforms of CSS and its partner platforms. Such damages for which compensation shall be owed include reasonable legal fees.</p>
                                    <ol start="8">
                                        <li><h3>REMAINING PROVISIONS</h3>
                                            <ol>
                                                <li>
                                                    <h4>Place of Performance</h4>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p>The place of performance for all services to be performed by CSS is the business domicile of CSS.</p>
                                    <ol start="8">
                                        <ol start="2">
                                            <li><h4>Amendments to the Contract Terms</h4></li>
                                        </ol>
                                    </ol>
                                    <p>CSS reserves the right to amend these GTC at any time. Between the User and CSS, the version of these GTC currently published on the websites of CSS shall apply. CSS will inform the User in advance and on its websites or by other such suitable means, as to any amendments to these GTC.</p>
                                    <ol start="8">
                                        <ol start="3">
                                            <li><h4>Changes to the Service Offer</h4></li>
                                        </ol>
                                    </ol>
                                    <p>CSS reserves the right to change or terminate, in part or in full, its service offer at any time, without prior warning or giving reasons for doing so. Such changes or suspension of the service offer shall not entitle the User to assert any claims against CSS.</p>
                                    <ol start="8">
                                        <ol start="4">
                                            <li><h4>Applicable Law and Place of Jurisdiction</h4></li>
                                        </ol>
                                    </ol>
                                    <p>These GTC and the other contract components, as well as any disputes which arise from or in connection with the contract relationship between CSS and the User, shall be exclusively governed by the substantive laws of Switzerland.</p>
                                    <p>The ordinary courts at the place of registered office of CSS shall have exclusive jurisdiction, whereby CSS shall also be entitled to seek redress against the User at the latter’s place of domicile.</p>



                                <h1>Conditions Générales</h1>
                                <h2>Conditions Générales d'utilisation</h2>

                                <blockquote className="blockquote">
                                    <p>Les conditions générales (CG) ci-après s’appliquent à tous les produits et services offerts par Cross Stream Solutions SARL (ci-après désignés par le terme "CSS"). En utilisant nos services, vous acceptez les conditions ci-après dans leur intégralité et sans modification</p>
                                </blockquote>                                

                                <ol>
                                    <li>
                                        <h3>CHAMP D’APPLICATION</h3>
                                        <ol>
                                            <li>
                                                <h4>Objet du contrat</h4>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p>Les présentes CG règlent le rapport juridique entre CSS etutilisateurs simples, demandeurs d’emploi, recruteurs et agences de recrutement (ci-après « utilisateur ») pour tous les produits et services offerts par CSS. Ces CG s’appliquent à toute utilisation des produits et des services offerts par CSS.</p>
                                <ol start="1">
                                    <ol start="2">
                                        <li>
                                            <h4>Conclusion du contrat</h4>
                                        </li>
                                    </ol>
                                </ol>
                                <p>La signature d’un contrat de collaboration écrit ou d’une offre écrite, l’acceptation expresse ou par actes concluants d’une confirmation de commande, le paiement d’une facture ou l’utilisation des produits et services offerts par CSS valent consentement aux présentes CG.</p>
                                <ol start="1">
                                    <ol start="3">
                                        <li>
                                            <h4>Parties intégrantes du contrat</h4>
                                        </li>
                                    </ol>
                                </ol>
                                <p>Font partie intégrante du contrat (dès lors qu’ils existent) : (i) le contrat de collaboration écrit conclu entre CSS et l’utilisateur, (ii) l’offre écrite spécifique à l’utilisateur, (iii) la confirmation de commande écrite, (iv) les présentes CG, et (v) le produit en vigueur à la date de la souscription d’un produit ou d’un service. En cas d’ambiguïté ou de contradiction entre les différents documents contractuels, l’ordre hiérarchique ci-dessus s’applique. La protection des données est régie par la Privacy Policy en vigueur (cf. notre déclaration de protection des données), telle que publiée sur la plateforme de CSS concernée.</p>
                                <ol start="2">
                                    <li>
                                        <h3>PRESTATIONS de CSS</h3>
                                        <ol>
                                            <li>
                                                <h4>Offre de prestations</h4>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p>L’utilisateur choisit les services à fournir par CSS parmi l’offre prestations disponibles au moment de la souscription. L’offre actualisée des prestations est décrite sur les sites web de CSS.</p>
                                <ol start="2">
                                    <ol start="2">
                                        <li>
                                            <h4>Compte Scenario et contingent d’IM</h4>
                                        </li>
                                    </ol>
                                </ol>
                                <p>CSS propose aux recruteurs et agences de recrutement notamment les services « Scénarios » et « IM ». L’étendue des prestations est déterminée par les descriptifs de produits en vigueur à la date de la souscription, publiés sur les sites web de CSS.</p>
                                <ol start="2">
                                    <ol start="2">
                                        <ol>
                                            <li><h4>Compte Scenario</h4></li>
                                        </ol>
                                    </ol>
                                </ol>
                                <p>Avec un compte Scenario, l’utilisateur dispose du nombre de scenario convenu pendant la durée fixe du contrat. Après la suppression d’un scenario, l’utilisateur est autorisé à insérer directement un nouveau scenario. Une limite de scenario de 8 signifie par exemple que l’utilisateur n’est pas autorisé à créer plus de 8 scenarios.</p>
                                <ol start="2">
                                    <ol start="2">
                                        <ol start="2">
                                            <li><h4>Contingent de IM (Instant Messages)</h4></li>
                                        </ol>
                                    </ol>
                                </ol>
                                <p>Avec un contingent d’IM, l’utilisateur achète un nombre défini de messages, fonction qu’il utilisera sur l’application mobile Crowlr en contactant un Utilisateur. Les historiques d’IM ne sont pas historier sur les plateformes de CSS. Le contingent vaut pour la durée fixée dans le descriptif de produit correspondant ou dans une autre partie intégrante du contrat. La part du contingent non utilisée est perdue et ne donne droit à aucun remboursement.</p>
                                <ol start="3">
                                    <li><h3>UTILISATION DE L’OFFRE DE PRESTATIONS</h3>
                                        <ol>
                                            <li>
                                                <h4>Mise en ligne de profils</h4>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p>Par la saisie de leurs données, les utilisateurs consentent à ce que le profil correspondant soit rendu accessible aux recruteurs et agences de recrutement sur les plateformes de CSS, et sur les plateformes partenaires. La non-parution ou la parution erronée d’un profil, ne fait naître aucune prétention envers CSS.</p>
                                <ol start="3">
                                    <ol start="2">
                                        <li><h4>Utilisations autorisées</h4></li>
                                    </ol>
                                </ol>
                                <p>L’utilisateur est le responsable exclusif du contenu de son profil. Il en garantit l’exactitude ainsi que l’actualité des informations. Seuls peuvent être publiés sur Crowlr, de CSS, les profils de candidats, d’utilisateurs qui respectent toutes les exigences légales.
                                    Les profils ne doivent notamment pas enfreindre les droits de propriété intellectuelle, les droits de la personnalité ni d’autres droits de tiers (cf. déclaration de protection des données). L’utilisateur s’engage à ne publier à travers CSS aucun contenu contraire au droit, en particulier aucun contenu à caractère raciste, pornographique ou portant atteinte à la personnalité.
                                    Les contenus suivants sont notamment prohibés :
                                    <ul>
                                        <li>profils contraires aux mœurs ou choquants</li>
                                        <li>les profils dont le contenu est incorrect ou fait défaut</li>
                                        <li>les profils pour les systèmes de vente à paliers multiples, les systèmes dits « boule de neige », les travaux à domicile, les cercles de dons, etc.</li>
                                        <li>les profils de mystery shopper</li>
                                    </ul>
                                    Tout profil publiée doit correspondre à un poste actuel ou passé.
                                    CSS se réserve le droit de supprimer tout profil ne répondant pas pleinement à ces exigences, sans avertissement ni indication de motifs. CSS se réserve en outre le droit de bloquer l’utilisateur ou l’accès de celui-ci à d’autres services de CSS.
                                </p>
                                <ol start="3">
                                    <ol start="3">
                                        <li><h4>Respect des règles</h4></li>
                                    </ol>
                                </ol>
                                <p>Les utilisateurs s’engagent à n’utiliser les plateformes de CSS et ses plateformes partenaires que dans le strict respect de toutes les exigences légales applicables. Les entreprises, agences de recrutement et locateurs de services garantissent qu’elles disposent d’une autorisation valable de pratiquer, respectivement, le placement privé ou la location de services, et s’engagent respectivement, à faire parvenir une copie de l’immatriculation de l’entreprise, de cette autorisation de pratiquer à CSS, dès la première demande.</p>
                                <ol start="4">
                                    <li><h3>DURÉE DU CONTRAT ET CONSERVATION DES DONNÉES</h3></li>
                                </ol>
                                <p>Toute résiliation anticipée d’un contrat conclu pour une durée fixe ou minimale est exclue. La totalité du prix convenu est due en cas de suppression anticipée de compte, de profils ou de données, et l’utilisateur n’a aucun droit au remboursement de prestations déjà payées. Il en va de même en cas de suppressions ou blocages effectués par CSS en application des présentes CG ou d’autres parties intégrantes du contrat.
                                    Après la fin du contrat, CSS n’est pas tenu de conserver les données et documents qui lui ont été transmis.</p>
                                <ol start="5">
                                    <li><h3>CONDITIONS DE PAIEMENT</h3></li>
                                </ol>
                                <p>TL’obligation de paiement de l’utilisateur naît à la conclusion du contrat, à l’activation du service, sans aucune déduction, à moins que d’autres modalités de paiement n’aient été expressément convenues. Si l’utilisateur est en demeure pour le paiement de factures, CSS a en outre le droit de suspendre ses prestations sans information préalable et de bloquer l’utilisateur ou l’accès de celui-ci à d’autres services de CSS.</p>
                                <ol start="6">
                                    <li><h3>OFFRES DE TIERS</h3></li>
                                </ol>
                                <p>CSS publie aussi sur sa plateforme, et applications mobiles des profils de tiers et/ou des liens vers des sites web de tiers. CSS ne répond ni de ces tiers, ni des contenus des sites web de tiers et décline toute responsabilité en lien avec ces tiers.</p>
                                <ol start="7">
                                    <li><h3>RESPONSABILITÉ</h3>
                                        <ol>
                                            <li>
                                                <h4>Responsabilité de CSS</h4>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p>CSS fournit ses prestations avec diligence et professionnalisme. La société ne peut toutefois garantir une disponibilité ininterrompue ni l’absence de défaut de ses prestations. CSS ne répond notamment pas des éventuelles interruptions de service, ni des éventuelles défaillances techniques et de leurs conséquences. Les profils accessibles sur les plateformes de
                                    CSS ont été créés par les utilisateurs correspondants et CSS ne garantit aucunement l’exactitude, ni l’intégralité de ces données.
                                    CSS s’efforce d’empêcher une exploitation non autorisée par des tiers des données publiées sur sa plateforme. Toute responsabilité de CSS en cas d’exploitation non autorisée par des tiers est néanmoins expressément exclue. CSS ne donne également aucune garantie quant au succès de la publication d’un profil. Dans tous les cas, la responsabilité de CSS se limite à l’intention frauduleuse ou à la négligence grave. Toute responsabilité pour des dommages indirects ou consécutifs est exclue.</p>
                                <ol start="7">
                                    <ol start="2">
                                        <li><h4>Responsabilité des utilisateurs</h4></li>
                                    </ol>
                                </ol>
                                <p>Le client répond de la licéité, de l’exactitude et du caractère complet des contenus mis à disposition ou transmis en vue de leur publication sur les plateformes de CSS et sur les plateformes partenaires de la société. CSS est responsable de la conservation diligente et confidentielle de ses données d’utilisateur (de ses identifiants et mots de passe, en particulier) et de toutes les activités effectuées à travers son accès. L’utilisateur répond envers CSS, indépendamment de toute faute, de tous les dommages et frais générés par la violation des présentes CG ou d’autres parties intégrantes du contrat. Il s’engage à libérer entièrement CSS de toutes les prétentions soulevées par des tiers contre CSS suite à l’utilisation par l’utilisateur des plateformes de CSS et de ses plateformes partenaires. Le dommage à réparer inclut aussi le coût raisonnable des poursuites judiciaires.</p>
                                <ol start="8">
                                    <li><h3>DISPOSITIONS DIVERSES</h3>
                                        <ol>
                                            <li>
                                                <h4>Lieu d’exécution</h4>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p>Le lieu d’exécution pour toutes les prestations fournies par CSS est au domicile de CSS.</p>
                                <ol start="8">
                                    <ol start="2">
                                        <li><h4>Modification des conditions contractuelles</h4></li>
                                    </ol>
                                </ol>
                                <p>CSS se réserve le droit de modifier les présentes CG à tout moment. La version actuelle des présentes CG, publiée sur les sites web de CSS, s’applique entre l’utilisateur et CSS. CSS informera les utilisateurs des modifications des présentes CG en temps utile, sur ses sites web ou d’une autre manière adéquate.</p>
                                <ol start="8">
                                    <ol start="3">
                                        <li><h4>Modifications de l’offre de prestations</h4></li>
                                    </ol>
                                </ol>
                                <p>CSS se réserve le droit de modifier l’offre de prestations à tout moment, sans avertissement ni indication de motifs, ou de cesser partiellement ou complètement son offre de prestations. Une telle modification ou cessation de l’offre de prestations n’habilite pas l’utilisateur à faire valoir des prétentions quelconques vis-à-vis de CSS.</p>
                                <ol start="8">
                                    <ol start="4">
                                        <li><h4>Droit applicable et for judiciaire</h4></li>
                                    </ol>
                                </ol>
                                <p>Les présentes CG et les autres parties intégrantes du contrat, ainsi que les éventuels différends issus de la relation contractuelle entre CSS et l’utilisateur sont exclusivement soumis au droit matériel suisse.</p>
                                <p>Tout litige relève de la compétence exclusive des tribunaux ordinaires au siège de CSS, cette dernière étant également en droit de poursuivre l’utilisateur à son domicile.</p>



                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                A Propos
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                                Politique de Confidentialité
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/terms-of-service">
                                                Conditions d'Utilisation
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                            </aside>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;